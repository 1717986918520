








// Components
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    GeneralLayout: () => getComponent('common/GeneralLayout'),
    CentralLoggingErrorsChart: () => getComponent('centralLogging/CentralLoggingErrorsChart'),
  },
})
export default class CentralLogging extends Vue {}
